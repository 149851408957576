import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Avatar } from "react-daisyui";
import { IChatMessageProps, MessageRole } from "../../types/types";
import { Illustration } from "../illustration/illustration";
import { ILLUSTRATIONS } from "../illustration/illustrations";

export const ChatMessage = ({ message }: IChatMessageProps) => {
  const messageRef = useRef<HTMLDivElement>(null);

  const isBot = message.role !== MessageRole.USER;

  return (
    <div className="mt-4">
      <div className="flex items-center">
        <Avatar shape="circle" className="mr-4">
          <div className="bg-neutral text-neutral-content h-10 w-10">
            {isBot ? (
              <Illustration illustration={ILLUSTRATIONS["yaron"]}/>
            ) : message.userInfo?.firstName && message.userInfo?.lastName ? (
              <FontAwesomeIcon icon={faUser} />
            ) : (
              <FontAwesomeIcon icon={faUser} />
            )}
          </div>
        </Avatar>
        <h4 className="font-semibold select-none">{isBot ? "Yaron" : "You"}</h4>
      </div>
      <div className="ml-16 mt-4">
        <div ref={messageRef}>{message.message}</div>
      </div>
    </div>
  );
};
