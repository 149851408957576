import React from "react";
import { Box, Typography } from "@mui/material";
import { Icon } from "../icon_new/icon_new";
import { ISVGIcon } from "../icon_new/icons_new";

interface Props {
  value: number;
  title?: string;
  icon: ISVGIcon;
}

const Rating = (props: Props) => {
  const { value, title, icon } = props;

  const fullStars = Math.floor(value);
  const hasHalfStar = value % 1 !== 0;
  const fractionalPart = value - Math.floor(value);

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        {Array.from({ length: fullStars }, (_, index) => (
          <Icon icon={icon} />
        ))}
        {hasHalfStar && <Icon icon={icon} percentage={fractionalPart * 100} />}
      </Box>
      {title ? <Typography ml={1}>{title}</Typography> : null}
    </Box>
  );
};

export default Rating;
