import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_DB_HOST,
  headers: {
    'Content-Type': 'application/json',
  },
});

const postData = async (endpoint: string, data: any) => {
  try {
    const response = await apiClient.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('Error making POST request:', error);
    throw error;
  }
};

const chat = async (conversation_id: string,messages: any)=>{
    return postData("chat", {
        conversation_id,
        messages
    })
}

export { postData, chat };
