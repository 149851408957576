import React from "react";
import MainMenu from "./menu/main-menu";

export const Header = () => {
  return (
    <>
      <MainMenu />
    </>
  );
};
