import {
  Box,
  Container,
  Grid,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { Section } from "../../components/section/section";
import { ILLUSTRATIONS } from "../../components/illustration/illustrations";
import { CVCard } from "../../components/card/card";
import LeftToRightReveal, {
  Animated,
} from "../../components/animated/animated";
import { WorkEntry } from "./work-entry";
import Rating from "../../components/rating/rating";
import { ICONS } from "../../components/icon_new/icons_new";
import ReactGA from 'react-ga4';

const StyledTypography = styled((props: TypographyProps) => (
  <Typography {...props} variant="h5" />
))(({ theme }) => ({
  color: "inherit", //
  fontWeight: "bold",
}));

const List: React.FC<{ items: string[] }> = ({ items }) => {
  return (
    <ul style={{ marginTop: "0", listStyle: 'circle' }}>
      {items.map((item, index) => (
        <Typography
          component="li"
          sx={{ marginLeft: "1rem", color: "inherit" }}
          key={index}
        >
          {item}
        </Typography>
      ))}
    </ul>
  );
};

const PageCV = () => {
  const theme = useTheme();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/cv', title: 'CV' });
  }, []);

  const contentsquare = {
    work: {
      title: "Senior Full Stack Engineer",
      url: "https://contentsquare.com/",
      illustration: ILLUSTRATIONS["csLogo"],
      from: "May 2020",
      to: "Apr 2024",
      years: 4,
    },
    words: [
      "aws",
      "golang",
      "docker",
      "TypeScript",
      "NestJS",
      "postgres",
      "Clickhouse",
      "terraform",
      "Grafana",
      "Kibana",
      "Prometheus",
    ],
  };
  const realcommerce = {
    work: {
      title: "Tech Lead (Full Stack)",
      url: "https://www.realcommerce.co.il/en",
      illustration:
        theme.palette.mode === "light"
          ? ILLUSTRATIONS["realcommerceLogo"]
          : ILLUSTRATIONS["realcommerceLogoWhite"],
      from: "Jan 2019",
      to: "May 2020",
      years: 1.5,
    },
    words: [
      "aws",
      "serverless",
      "TypeScript",
      "NestJS",
      "React",
      "angular",
      "drupal",
      "postgres",
      "ElasticSearch",
    ],
  };
  const tech = {
    work: {
      title: "Full Stack Developer",
      url: "https://tech.co.il/",
      illustration: ILLUSTRATIONS["techLogo"],
      from: "March 2016",
      to: "Jan 2019",
      years: 2.7,
    },
    words: [
      "VueJS",
      "WordPress",
      "Laravel",
      "VueJs",
      "TypeScript",
      "ElasticSearch",
    ],
  };
  const i24 = {
    work: {
      title: "Video editor",
      url: "https://www.i24news.tv/en",
      illustration:
        theme.palette.mode === "light"
          ? ILLUSTRATIONS["i24LogoLight"]
          : ILLUSTRATIONS["i24Logo"],
      from: "Sep 2013",
      to: "Feb 2016",
      years: 2.5,
    },
    words: [],
  };
  const bezalel = {
    work: {
      title: "B.A. Screen Based Arts",
      url: "https://www.bezalel.ac.il/en",
      illustration:
        theme.palette.mode === "light"
          ? ILLUSTRATIONS["bezalelLogo"]
          : ILLUSTRATIONS["bezalelLogoDark"],
      from: "September 2007",
      to: "September 2010",
      years: 4,
    },
    words: [
      "Animation",
      "Video editing",
      "Art History",
      "TypeScript",
    ],
  };
  const johnbryce = {
    work: {
      title: "Full Stack Developer",
      url: "https://www.johnbryce.co.il/",
      illustration:
        theme.palette.mode === "light"
          ? ILLUSTRATIONS["johnbryce"]
          : ILLUSTRATIONS["johnbryce"],
      from: "September 2015",
      to: "September 2016",
      years: 1,
    },
    words: [
      "html",
      "css",
      "JavaScript",
      "PHP",
      "Angular",
      "postgres",
    ],
  };
  return (
    <Container>
      <Typography
        component="h1"
        sx={{ textAlign: "center", margin: "1rem" }}
        variant="h1"
      >
        Resume
      </Typography>
      <Section title="Skills" illustration={ILLUSTRATIONS["skills"]}>
        <Grid py={8} container spacing={2}>
          <Grid item md={4} xs={12}>
            <CVCard title="backend">
              <StyledTypography variant="h5">
                Languages & Frameworks
              </StyledTypography>
              <List items={["golang", "Nodejs (Nestjs)", "python", "PHP"]} />
              <StyledTypography variant="h5">DBs</StyledTypography>
              <List items={["MySQL", "Postgres", "mongoDB", "clickhouse"]} />
              <StyledTypography variant="h5">AI</StyledTypography>
              <List items={["LangChain", "PineCone", "RAG"]} />
            </CVCard>
          </Grid>
          <Grid item md={4} xs={12}>
            <CVCard title="frontend">
              <StyledTypography variant="h5">
                Languages & Frameworks
              </StyledTypography>
              <List
                items={[
                  "Javascript, Typescript",
                  "React, material-ui",
                  "Vuejs, vuex, vuetify",
                  "angular, rxjs",
                ]}
              />
            </CVCard>
          </Grid>
          <Grid item md={4} xs={12}>
            <CVCard title="devops">
              <StyledTypography variant="h5">Cloud</StyledTypography>
              <List
                items={[
                  "aws (S3, EC2, EKS, RDS, CloudFront, Amplify)",
                  "kubernetes",
                  "terraform",
                ]}
              />
              <StyledTypography variant="h5">CI/CD</StyledTypography>
              <List
                items={["docker", "prometheus", "github actions", "jenkins"]}
              />
            </CVCard>
          </Grid>
        </Grid>
      </Section>
      <Section title="Work History" illustration={ILLUSTRATIONS["notes"]}>
        <WorkEntry work={contentsquare.work} words={contentsquare.words}>
          <p>
            developed in the frontend team the browser side script which
            collected browser events
          </p>
          <p>developed internal tools for system observability</p>
          <ul style={{ marginLeft: "1rem", listStyle: 'disc' }}>
            <li>TTB (Total Blocking Time) optimization</li>
            <li>JS native functions protections (patent pending)</li>
            <li>github-slack integration tool</li>
          </ul>
        </WorkEntry>
        <WorkEntry work={realcommerce.work} words={realcommerce.words}>
          <p>lead several projects in NodeJs/Drupal/React/Angular</p>
          <ul style={{ marginLeft: "1rem", listStyle: 'disc' }}>
            <li>Time tracking and shift management application</li>
            <li>
              Inventory items update, search and filter application & site
            </li>
            <li>Key management application for Dan hotels</li>
          </ul>
        </WorkEntry>
        <WorkEntry work={tech.work} words={tech.words}>
          <p>developed sites and applications in WordPress</p>
        </WorkEntry>
        <WorkEntry work={i24.work} words={i24.words}></WorkEntry>
      </Section>
      <Section title="Education" illustration={ILLUSTRATIONS["education"]}>
        <WorkEntry work={johnbryce.work} words={johnbryce.words}>
        </WorkEntry>
        <WorkEntry work={bezalel.work} words={bezalel.words}>
        <p>Bezalel academy, Jerusalem</p>
        </WorkEntry>
      </Section>
      <Section title="Languages" illustration={ILLUSTRATIONS["languages"]}>
        <Box sx={{ width: "200px", overflow: "none" }}>
          <Box my={4}>
            <LeftToRightReveal triggerOnce={false}>
              <Rating title="Hebrew" icon={ICONS["israel"].icon} value={5} />
            </LeftToRightReveal>
          </Box>
          <Box my={4}>
            <LeftToRightReveal delay={200} triggerOnce={false}>
              <Rating title="English" icon={ICONS["uk"].icon} value={5} />
            </LeftToRightReveal>
          </Box>
          <Box my={4}>
            <LeftToRightReveal delay={400} triggerOnce={false}>
              <Rating title="French" icon={ICONS["france"].icon} value={3} />
            </LeftToRightReveal>
          </Box>
        </Box>
      </Section>
    </Container>
  );
};

export default PageCV;
