import {
  Box,
  Grid,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import { Illustration } from "../../components/illustration/illustration";
import {
  IIllustration,
  ILLUSTRATIONS,
} from "../../components/illustration/illustrations";
import LeftToRightReveal, {
  Animated,
} from "../../components/animated/animated";
import { ReactNode } from "react";
import { Chips } from "../../components/chips/chips";
import Rating from "../../components/rating/rating";
import { ICONS } from "../../components/icon_new/icons_new";

interface IWork {
  work?: WorkProps;
  children?: ReactNode;
  words?: string[];
  arrow?: boolean;
}

const StyledTypography = styled((props: TypographyProps) => (
  <Typography {...props} variant="h5" />
))(({ theme }) => ({
  fontWeight: "bold",
  display: "inline-block",
}));

export const WorkEntry = (props: IWork) => {
  const { work, children, words, arrow = false } = props;
  const theme = useTheme();
  const backgroundColor =
    theme.palette.mode === "light" ? "#ffffaf" : "#06041b";
  return (
    <>
      <Box
        sx={{
          backgroundColor,
          marginBottom: "6rem",
          marginTop: "4rem",
          padding: "1rem",
          borderRadius: "0.5rem",
          boxShadow:
            theme.palette.mode === "light"
              ? "4px 3px 19px #232121;"
              : "4px 3px 19px #505050c4",
        }}
      >
        {arrow ? (
          <Animated
            animation={{
              tranformAfter: "translateY(-20px) translateX(-20px)",
              tranformBefore: "translateY(0) translateX(0)",
            }}
          >
            <Illustration
              sx={{
                position: "absolute",
                rotate: "180deg",
                width: "4rem",
                height: "4rem",
                transform: "translateY(100%) translateX(25%)",
              }}
              illustration={ILLUSTRATIONS["arrow01"]}
            />
          </Animated>
        ) : null}
        <WorkHeadline
          {...work}
          title={work!.title}
          url={work!.url}
          illustration={work!.illustration}
          from={work!.from}
          to={work!.to}
          years={work!.years}
        />
        {children}
        {words ? (
          <Box my={2}>
            <Chips words={words} />
          </Box>
        ) : null}
      </Box>
    </>
  );
};

interface WorkProps {
  title: string;
  url: string;
  illustration: IIllustration;
  from: string;
  to: string;
  years: number;
}

const WorkHeadline = (props: WorkProps) => {
  const { illustration, url, title, from, to, years } = props;
  return (
    <>
      <a href={url} target="_blank" rel="noreferrer">
        <Illustration sx={{ width: "15rem" }} illustration={illustration} />
      </a>
      <Grid
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        item
        xs={12}
      >
        <Grid item>
          <StyledTypography>{title}</StyledTypography>
        </Grid>
        <Grid item>
          <StyledTypography>
            {from} - {to}
          </StyledTypography>
          <LeftToRightReveal>
            <Rating
              value={years}
              title={`[${years} years]`}
              icon={ICONS["year"].icon}
            />
          </LeftToRightReveal>
        </Grid>
      </Grid>
    </>
  );
};
