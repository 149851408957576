import React, { Suspense } from "react";

import { Routes } from "react-router-dom";

import { Route } from "react-router-dom";
import { HomePage } from "../pages/homepage/homepage";
import Layout from "../layout/layout";
import PageCV from "../pages/cv/page-cv";
import { PageChatbot } from "../pages/chatbot/chatbot";


export const MyRouter = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<PageCV></PageCV>} />
          <Route path="cv" element={<PageCV></PageCV>} />
          <Route path="chatbot" element={<PageChatbot></PageChatbot>} />
        </Route>
      </Routes>
    </Suspense>
  );
};
