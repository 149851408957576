import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMailReply } from "@fortawesome/free-solid-svg-icons";
import { ChatUI } from "../../components/chat";
import { Conversations, MessageRole } from "../../types/types";
import { chat } from "../../services/chat";
import { generateRandomHash } from "../../utils/utils";
import ReactGA from 'react-ga4'

const TEST_USER_INFO = { firstName: "Test", lastName: "User" };
export const PageChatbot = () => {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/chatbot', title: 'CHATBOT' });
  }, []);
  const [isQuerying, setIsQuerying] = useState<boolean>(false);
  const [conversationID, setConversationID]=useState<string>(generateRandomHash(20))

  const [chatConversations, setChatConversations] = useState<Conversations>([
    {
      id: "1",
      role: MessageRole.ASSISTANT,
      message: "Hi, nice to meet you!",
    },
  ]);

  const handleSubmit = useCallback(async (value: string) => {
    setIsQuerying(true);
    let chatMessages = [
      ...chatConversations,
      {
        userInfo: TEST_USER_INFO,
        id: (chatConversations.length + 1).toString(),
        role: MessageRole.USER,
        message: value,
      },
    ];
    setChatConversations((conversations) => [
      ...conversations,
      {
        userInfo: TEST_USER_INFO,
        id: (conversations.length + 1).toString(),
        role: MessageRole.USER,
        message: value,
      },
    ]);

    const data = await chat(conversationID,chatMessages);
    setIsQuerying(false);
    setChatConversations((conversations) => [
      ...conversations,
      {
        id: (conversations.length + 1).toString(),
        role: MessageRole.ASSISTANT,
        message: data.newMessage,
      },
    ]);
  }, [chatConversations]);

  return (
    <>
      <ChatUI
        isQuerying={isQuerying}
        onSubmit={handleSubmit}
        placeholder="Type here to speak to my ai version"
        disabled={isQuerying}
        conversations={chatConversations}
        customSubmitIcon={<FontAwesomeIcon icon={faMailReply} />}
      />
    </>
  );
};
